import React, { FC, memo, useMemo, useEffect, useState } from 'react';
import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material';
import LabelWrapper from '../../common/LabelWrapper';
import BoxComponent from '../../custom/BoxComponent';
import { ShorelineNourishment } from '../../../models/inputTypes/ShorelineFields';
import CustomInputWithLabel from '../../common/CustomInputWithLabel';
import CustomSwitch from '../../custom/CustomSwitch';
import CustomInput from '../../custom/CustomInput';
import CustomSelect from '../../custom/CustomSelect';
import CustomMenuItem from '../../custom/CustomMenuItem';
import {
  NourishmentsInitialObject,
  ShorefaceNourishmentsInitialObject,
} from '../../../utils/initialStates/shorelineInputState';
import { NourishmentsObject, ShorefaceNourishmentsObject } from '../../../models/inputTypes/ShorelineFields';

const styles = {
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  wrap: {
    flexWrap: 'wrap',
  },
  flexCenterBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  spaceBetweenBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    maxWidth: '900px',
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  flexBetweenBox: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    maxWidth: '1100px',
    justifyContent: 'space-between',
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
} as const;
type InputsProps = {
  projectId: string;
  inputState: ShorelineNourishment;
  setInputState: (value: ShorelineNourishment) => void;
};

const ShorelineNourishmentInputsGroup: FC<InputsProps> = ({ projectId, inputState, setInputState }) => {
  const switchChange = (e: any) => {
    if (e.target.name === 'nourish') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
      });
      return;
    }
    if (e.target.name === 'shoreface') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
      });
      return;
    }
  };

  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const newValue = event.target.value as any;
    if (event.target.name === 'numNourishments') {
      const nourishments: NourishmentsObject[] = [];
      for (let i = 0; i < newValue; i++) {
        if (inputState.nourishments[i]) {
          nourishments.push(inputState.nourishments[i]);
        } else {
          nourishments.push(NourishmentsInitialObject);
        }
      }
      setInputState({ ...inputState, [event.target.name]: newValue, nourishments: nourishments });
      return;
    }
    if (event.target.name === 'numShorefaceNour') {
      const nourishments: ShorefaceNourishmentsObject[] = [];
      for (let i = 0; i < newValue; i++) {
        if (inputState.shorefaceNour[i]) {
          nourishments.push(inputState.shorefaceNour[i]);
        } else {
          nourishments.push(ShorefaceNourishmentsInitialObject);
        }
      }
      setInputState({ ...inputState, [event.target.name]: newValue, shorefaceNour: nourishments });
      return;
    }
  };

  const onDynamicInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    if (name == 'nourishments') {
      const newCodeArray = inputState.nourishments.slice();
      newCodeArray[index] = { ...newCodeArray[index], [e.target.name]: e.target.value };
      setInputState({ ...inputState, nourishments: newCodeArray });
      return;
    }
    if (name == 'shorefaceNour') {
      const newCodeArray = inputState.shorefaceNour.slice();
      if (e.target.name == 'k') {
        newCodeArray[index] = {
          ...newCodeArray[index],
          [e.target.name]: e.target.value === '' ? undefined : +e.target.value,
        };
      } else {
        newCodeArray[index] = { ...newCodeArray[index], [e.target.name]: e.target.value };
      }
      setInputState({ ...inputState, shorefaceNour: newCodeArray });
      return;
    }
  };

  const nourishments = useMemo(() => {
    return inputState.nourishments.map((item, i) => (
      <>
        <BoxComponent sx={{ ...styles.flexStartBox, ...styles.childSpacing }} disabled={!inputState.nourish}>
          <Box>
            <LabelWrapper label={'First point coordinates'}>
              <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                <CustomInputWithLabel
                  required
                  step={'any'}
                  maxWidth={'80px'}
                  errorText={'Enter number'}
                  type={'number'}
                  value={item.swX}
                  onChange={(e) => onDynamicInputChange(i, e, 'nourishments')}
                  name={'swX'}
                  label={'X'}
                />
                <CustomInputWithLabel
                  required
                  step={'any'}
                  maxWidth={'80px'}
                  errorText={'Enter number'}
                  type={'number'}
                  value={item.swY}
                  onChange={(e) => onDynamicInputChange(i, e, 'nourishments')}
                  name={'swY'}
                  label={'Y'}
                />
              </Box>
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Last point coordinates'}>
              <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                <CustomInputWithLabel
                  required
                  step={'any'}
                  maxWidth={'80px'}
                  errorText={'Enter numer'}
                  type={'number'}
                  value={item.neX}
                  onChange={(e) => onDynamicInputChange(i, e, 'nourishments')}
                  name={'neX'}
                  label={'X'}
                />
                <CustomInputWithLabel
                  required
                  step={'any'}
                  maxWidth={'80px'}
                  errorText={'Enter number'}
                  type={'number'}
                  value={item.neY}
                  onChange={(e) => onDynamicInputChange(i, e, 'nourishments')}
                  name={'neY'}
                  label={'Y'}
                />
              </Box>
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Total volume (m\u00B3)'}>
              <CustomInput
                required
                min={0}
                maxWidth={'120px'}
                step={'any'}
                errorText={'Enter positive number'}
                type={'number'}
                value={item.rate}
                onChange={(e) => onDynamicInputChange(i, e, 'nourishments')}
                name={'rate'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Start time'}>
              <CustomInput
                required
                errorText={'Enter valid date'}
                fullWidth
                type={'date'}
                onChange={(e) => onDynamicInputChange(i, e, 'nourishments')}
                value={item.tstart}
                name={'tstart'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'End time'}>
              <CustomInput
                min={item.tstart}
                required
                errorText={'Enter valid date'}
                fullWidth
                type={'date'}
                onChange={(e) => onDynamicInputChange(i, e, 'nourishments')}
                value={item.tend}
                name={'tend'}
              />
            </LabelWrapper>
          </Box>
        </BoxComponent>
      </>
    ));
  }, [inputState.nourishments, inputState.nourish]);

  const required = useMemo(() => {
    return inputState.shorefaceNour.some((dict) => dict.k !== undefined);
  }, [inputState.shorefaceNour]);

  const warning = (index: number) => {
    return inputState.shorefaceNour[index].k == undefined && !required ? 'Leave empty for auto-calculation' : undefined;
  };

  const shorefaceNourishments = useMemo(() => {
    return inputState.shorefaceNour.map((item, i) => (
      <>
        <BoxComponent my={1} sx={{ ...styles.flexStartBox, ...styles.childSpacing }} disabled={!inputState.shoreface}>
          <Box>
            <LabelWrapper label={'First point coordinates'}>
              <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                <CustomInputWithLabel
                  required
                  step={'any'}
                  maxWidth={'80px'}
                  errorText={'Enter number'}
                  type={'number'}
                  value={item.swX}
                  onChange={(e) => onDynamicInputChange(i, e, 'shorefaceNour')}
                  name={'swX'}
                  label={'X'}
                />
                <CustomInputWithLabel
                  required
                  step={'any'}
                  maxWidth={'80px'}
                  errorText={'Enter number'}
                  type={'number'}
                  value={item.swY}
                  onChange={(e) => onDynamicInputChange(i, e, 'shorefaceNour')}
                  name={'swY'}
                  label={'Y'}
                />
              </Box>
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Last point coordinates'}>
              <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                <CustomInputWithLabel
                  required
                  step={'any'}
                  maxWidth={'80px'}
                  errorText={'Enter numer'}
                  type={'number'}
                  value={item.neX}
                  onChange={(e) => onDynamicInputChange(i, e, 'shorefaceNour')}
                  name={'neX'}
                  label={'X'}
                />
                <CustomInputWithLabel
                  required
                  step={'any'}
                  maxWidth={'80px'}
                  errorText={'Enter number'}
                  type={'number'}
                  value={item.neY}
                  onChange={(e) => onDynamicInputChange(i, e, 'shorefaceNour')}
                  name={'neY'}
                  label={'Y'}
                />
              </Box>
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Total volume (m\u00B3)'}>
              <CustomInput
                required
                min={0}
                maxWidth={'120px'}
                step={'any'}
                errorText={'Enter positive number'}
                type={'number'}
                value={item.rate}
                onChange={(e) => onDynamicInputChange(i, e, 'shorefaceNour')}
                name={'rate'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Start time'}>
              <CustomInput
                required
                errorText={'Enter valid date'}
                fullWidth
                type={'date'}
                onChange={(e) => onDynamicInputChange(i, e, 'shorefaceNour')}
                value={item.tstart}
                name={'tstart'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'d50 (mm)'}>
              <CustomInput
                required
                min={0}
                maxWidth={'75px'}
                step={'any'}
                errorText={'Enter positive number'}
                type={'number'}
                value={item.d50}
                onChange={(e) => onDynamicInputChange(i, e, 'shorefaceNour')}
                name={'d50'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Placement depth (m)'}>
              <CustomInput
                required
                max={-0.0000000000000000001}
                maxWidth={'150px'}
                step={'any'}
                errorText={'Enter negative number'}
                type={'number'}
                value={item.d}
                onChange={(e) => onDynamicInputChange(i, e, 'shorefaceNour')}
                name={'d'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Alongshore length (m)'}>
              <CustomInput
                required
                min={0}
                maxWidth={'150px'}
                step={'any'}
                errorText={'Enter positive number'}
                type={'number'}
                value={item.nour_len}
                onChange={(e) => onDynamicInputChange(i, e, 'shorefaceNour')}
                name={'nour_len'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Diffusion coefficient'}>
              <CustomInput
                required={required}
                min={0}
                maxWidth={'120px'}
                step={'any'}
                errorText={required ? 'All fields required' : 'Enter positive number'}
                warningText={warning(i)}
                type={'number'}
                value={item.k}
                onChange={(e) => onDynamicInputChange(i, e, 'shorefaceNour')}
                name={'k'}
              />
            </LabelWrapper>
          </Box>
        </BoxComponent>
      </>
    ));
  }, [inputState.shorefaceNour, inputState.shoreface]);

  return (
    <Box>
      <Typography mb={1} variant={'subtitle1'}>
        Beach nourishment
      </Typography>
      <Box mb={3}>
        <LabelWrapper label={'Include beach nourishment'}>
          <Grid container justifyContent={'center'} width={'120px'}>
            <CustomSwitch checked={inputState.nourish} name={'nourish'} onChange={switchChange} />
          </Grid>
        </LabelWrapper>
      </Box>
      <BoxComponent mb={3} disabled={!inputState.nourish}>
        <LabelWrapper label={'Number of locations'}>
          <CustomSelect
            value={inputState.numNourishments}
            onChange={onInputChange}
            name={'numNourishments'}
            minWidth={'100px'}
            defaultValue={1}
            displayEmpty
          >
            <CustomMenuItem value={1}>
              <Typography variant={'subtitle2'}>{1}</Typography>
            </CustomMenuItem>
            <CustomMenuItem value={2}>
              <Typography variant={'subtitle2'}>{2}</Typography>
            </CustomMenuItem>
            <CustomMenuItem value={3}>
              <Typography variant={'subtitle2'}>{3}</Typography>
            </CustomMenuItem>
            <CustomMenuItem value={4}>
              <Typography variant={'subtitle2'}>{4}</Typography>
            </CustomMenuItem>
            <CustomMenuItem value={5}>
              <Typography variant={'subtitle2'}>{5}</Typography>
            </CustomMenuItem>
          </CustomSelect>
        </LabelWrapper>
      </BoxComponent>
      <BoxComponent mb={3} disabled={!inputState.nourish}>
        {nourishments}
      </BoxComponent>
      <Typography mb={1} variant={'subtitle1'}>
        Shoreface nourishment
      </Typography>
      <Box mb={3}>
        <LabelWrapper label={'Include shoreface nourishment'}>
          <Grid container justifyContent={'center'} width={'130px'}>
            <CustomSwitch checked={inputState.shoreface} name={'shoreface'} onChange={switchChange} />
          </Grid>
        </LabelWrapper>
      </Box>
      <BoxComponent mb={3} disabled={!inputState.shoreface}>
        <LabelWrapper label={'Number of locations'}>
          <CustomSelect
            value={inputState.numShorefaceNour}
            onChange={onInputChange}
            name={'numShorefaceNour'}
            minWidth={'100px'}
            defaultValue={1}
            displayEmpty
          >
            <CustomMenuItem value={1}>
              <Typography variant={'subtitle2'}>{1}</Typography>
            </CustomMenuItem>
            <CustomMenuItem value={2}>
              <Typography variant={'subtitle2'}>{2}</Typography>
            </CustomMenuItem>
            <CustomMenuItem value={3}>
              <Typography variant={'subtitle2'}>{3}</Typography>
            </CustomMenuItem>
            <CustomMenuItem value={4}>
              <Typography variant={'subtitle2'}>{4}</Typography>
            </CustomMenuItem>
            <CustomMenuItem value={5}>
              <Typography variant={'subtitle2'}>{5}</Typography>
            </CustomMenuItem>
          </CustomSelect>
        </LabelWrapper>
      </BoxComponent>
      <BoxComponent disabled={!inputState.shoreface}>{shorefaceNourishments}</BoxComponent>
    </Box>
  );
};

export default memo(ShorelineNourishmentInputsGroup);
