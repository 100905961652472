export const findFileType = (name: string) => {
  if (name.endsWith('.pdf')) return 'pdf';
  if (name.endsWith('.csv')) return 'data';
  if (name.endsWith('.json')) return 'setup';
  if (name.endsWith('.png')) return 'image';
  if (name.endsWith('.jpg')) return 'image';
  if (name.endsWith('.dxf')) return 'dxf';
  if (name.endsWith('.txt')) return 'txt';
  if (name.endsWith('.kml')) return 'kml';
  return 'other';
};
