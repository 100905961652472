import React, { FC, memo, useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import LabelWrapper from '../../common/LabelWrapper';
import BoxComponent from '../../custom/BoxComponent';
import { ShorelineStructure } from '../../../models/inputTypes/ShorelineFields';
import { FileType } from '../../../models/inputTypes/FileType';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import { shorelineStructureInitialState } from '../../../utils/initialStates/shorelineInputState';
import CustomSwitch from '../../custom/CustomSwitch';

const styles = {
  spaceBetweenContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    maxWidth: '730px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& >:nth-child(n)': {
      my: 1,
    },
  },
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  wrap: {
    flexWrap: 'wrap',
  },
  flexCenterBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  spaceBetweenBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    maxWidth: '900px',
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  flexBetweenBox: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    maxWidth: '1100px',
    justifyContent: 'space-between',
  },
} as const;
type InputsProps = {
  projectId: string;
  inputState: ShorelineStructure;
  setInputState: (value: ShorelineStructure) => void;
};
const ShorelineStructureInputsGroup: FC<InputsProps> = ({ projectId, inputState, setInputState }) => {
  const onFileChange = (file: FileType | undefined, fieldName: string) => {
    setInputState({ ...inputState, [fieldName]: file });
  };

  const switchChange = (e: any) => {
    if (e.target.name === 'struct') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        structFile: undefined,
      });
      return;
    }
    if (e.target.name === 'revetment') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        // nourishStart: ShorelineStructureInputsGroup.tsx.nourishStart,
        // nourishRate: ShorelineStructureInputsGroup.tsx.nourishRate,
        revetFile: undefined,
      });
      return;
    }
  };

  return (
    <Box>
      <Typography mb={1} variant={'subtitle1'}>
        Groynes / Breakwaters
      </Typography>
      <Box sx={{ ...styles.spaceBetweenContainer }} mb={3}>
        <Box>
          <LabelWrapper label={'Include groynes / breakwaters'}>
            <Grid container justifyContent={'center'} width={'120px'}>
              <CustomSwitch checked={inputState.struct} name={'struct'} onChange={switchChange} />
            </Grid>
          </LabelWrapper>
        </Box>
        <BoxComponent disabled={!inputState.struct}>
          <LabelWrapper label={'Insert groynes / breakwaters file'}>
            <ButtonFileDialog
              typePage={'SHORELINES'}
              dataTxtFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || !inputState.struct}
              file={inputState.structFile}
              setFile={(file) => onFileChange(file, 'structFile')}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>
      <Typography mb={1} variant={'subtitle1'}>
        Revetments
      </Typography>
      <Box sx={{ ...styles.spaceBetweenContainer }}>
        <Box>
          <LabelWrapper label={'Include revetment'}>
            <Grid container justifyContent={'center'} width={'120px'}>
              <CustomSwitch checked={inputState.revetment} name={'revetment'} onChange={switchChange} />
            </Grid>
          </LabelWrapper>
        </Box>
        <BoxComponent disabled={!inputState.revetment}>
          <LabelWrapper label={'Insert revetment file'}>
            <ButtonFileDialog
              typePage={'SHORELINES'}
              dataTxtFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || !inputState.revetment}
              file={inputState.revetFile}
              setFile={(file) => onFileChange(file, 'revetFile')}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>
    </Box>
  );
};

export default memo(ShorelineStructureInputsGroup);
