import React, { FC, memo } from 'react';
import { Box, Typography, FormControlLabel, RadioGroup } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import LabelWrapper from '../../common/LabelWrapper';
import BoxComponent from '../../custom/BoxComponent';
import CustomRadio from '../../custom/CustomRadio';
import { ShorelineSimulationTime } from '../../../models/inputTypes/ShorelineFields';
import { shorelineSimulationTimeInitialState } from '../../../utils/initialStates/shorelineInputState';

const styles = {
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  wrap: {
    flexWrap: 'wrap',
  },
} as const;

type InputsProps = {
  inputState: ShorelineSimulationTime;
  setInputState: (value: ShorelineSimulationTime) => void;
};

const ShorelineSimulationTimeInputsGroup: FC<InputsProps> = ({ inputState, setInputState }) => {
  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputState({
      ...inputState,
      [e.target.name]: e.target.value,
      timestep: shorelineSimulationTimeInitialState.timestep,
    });
  };

  return (
    <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
      <Box>
        <Typography my={1} variant={'subtitle1'}>
          Time Parameters
        </Typography>
        <BoxComponent sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
          <Box>
            <LabelWrapper label={'Simulation start'}>
              <CustomInput
                required
                errorText={'Enter valid date'}
                fullWidth
                type={'date'}
                onChange={onNumberChange}
                value={inputState.reftime}
                name={'reftime'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Simulation end'}>
              <CustomInput
                min={inputState.reftime}
                required
                errorText={'Enter valid date'}
                fullWidth
                type={'date'}
                onChange={onNumberChange}
                value={inputState.simend}
                name={'simend'}
              />
            </LabelWrapper>
          </Box>
        </BoxComponent>
      </Box>
      <Box>
        <Typography my={2} variant={'subtitle1'}>
          Timestep
        </Typography>
        <Box sx={{ ...styles.wrap, ...styles.flexStartBox, ...styles.childSpacing }}>
          <BoxComponent>
            <RadioGroup aria-label="timeType" name="timeType" value={inputState.timeType} onChange={onChangeRadio}>
              <FormControlLabel
                labelPlacement={'start'}
                value="adaptive"
                control={<CustomRadio />}
                label={
                  <Typography align={'right'} variant={'subtitle2'} color={'info.main'}>
                    Adaptive
                  </Typography>
                }
              />
              <FormControlLabel
                labelPlacement={'start'}
                value="constant"
                control={<CustomRadio />}
                label={
                  <Typography variant={'subtitle2'} color={'info.main'}>
                    Constant
                  </Typography>
                }
              />
            </RadioGroup>
          </BoxComponent>
          <BoxComponent disabled={inputState.timeType == 'adaptive'}>
            <Box sx={{ ...styles.flexStartBox }}>
              <Box>
                <LabelWrapper label={'Time step (hrs)'}>
                  <CustomInput
                    min={0.00000000000001}
                    required
                    step={'any'}
                    disabled={inputState.timeType == 'adaptive'}
                    errorText={'Enter positive number'}
                    maxWidth={'120px'}
                    onChange={onNumberChange}
                    type={'number'}
                    value={inputState.timestep}
                    name={'timestep'}
                  />
                </LabelWrapper>
              </Box>
            </Box>
          </BoxComponent>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(ShorelineSimulationTimeInputsGroup);
