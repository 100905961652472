import { ShorelineType } from '../../models/jobModels/shorelineType';
import {
  ShorelineNumericalDomain,
  ShorelineSimulationTime,
  ShorelineWaveClima,
  ShorelineModelFeatures,
  ShorelineStructure,
  ShorelineNourishment,
  ShorelinePlotProperties,
  ShorelinePlotLabelPdf,
  ShorelineOutput,
} from '../../models/inputTypes/ShorelineFields';
import {
  shorelineNumericalDomainInitialState,
  shorelineNourishmentInitialState,
  shorelinePlotPropertiesInitialState,
  shorelineWaveClimaInitialState,
  shorelinePlotLabelPdfInitialState,
} from '../initialStates/shorelineInputState';
import { SrfSubmission } from '../../models/inputTypes/SrfFields';
import { srfSubmissionInitialState } from '../initialStates/SrfInputState';
import { prepareInputFileSimulation } from '../functions/prepareInputFileSimulation';
import {
  NourishmentsObject,
  ShorefaceNourishmentsObject,
  PlotObject,
  WaveGenObject,
} from '../../models/inputTypes/ShorelineFields';
import {
  prepareNourishments,
  prepareShorefaceNourishments,
  prepareRefCoast,
  prepareWaveGen,
} from '../functions/shorelineFilePreparation';

export type ShorelineSimulationPreparationResponse = {
  numerical: ShorelineNumericalDomain;
  simulationTime: ShorelineSimulationTime;
  waveClimate: ShorelineWaveClima;
  modelFeatures: ShorelineModelFeatures;
  structure: ShorelineStructure;
  nourishment: ShorelineNourishment;
  plotPropetries: ShorelinePlotProperties;
  pdfLabel: ShorelinePlotLabelPdf;
  output: ShorelineOutput;
  submission: SrfSubmission;
};

export const shorelineSimulationPreparation = (
  simulationFile: ShorelineType,
): ShorelineSimulationPreparationResponse | undefined => {
  const I_Coast = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Coast');
  const I_WaveClima = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_WaveClima');
  const I_Timeseries = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Timeseries');
  const I_Struct = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Struct');
  const I_Revet = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Revet');
  const phifFile = prepareInputFileSimulation(simulationFile.parameters.input_files, 'phifFile');
  const logoFile = simulationFile.parameters?.kwargs?.logo_file;

  const nourishments: NourishmentsObject[] = prepareNourishments(
    simulationFile.parameters.nourishments ?? shorelineNourishmentInitialState.nourishments,
  );

  const shorefaceNour: ShorefaceNourishmentsObject[] = prepareShorefaceNourishments(
    simulationFile.parameters.shorefaceNour ?? shorelineNourishmentInitialState.shorefaceNour,
  );

  const LDBplotFiles = simulationFile.parameters.input_files.filter((file) => file.alias?.startsWith('LDBPlot_'));
  const timeFiles = simulationFile.parameters.input_files.filter((file) => file.alias?.startsWith('Timeseries_'));
  const waveFiles = simulationFile.parameters.input_files.filter((file) => file.alias?.startsWith('WaveClima_'));

  const plotFiles: PlotObject[] = prepareRefCoast(
    LDBplotFiles,
    simulationFile.parameters.labels ?? [],
    simulationFile.parameters.colors ?? [],
  );

  const timeseries: WaveGenObject[] = prepareWaveGen(
    timeFiles,
    simulationFile.parameters.X ?? [],
    simulationFile.parameters.Y ?? [],
  );

  const waveclima: WaveGenObject[] = prepareWaveGen(
    waveFiles,
    simulationFile.parameters.X ?? [],
    simulationFile.parameters.Y ?? [],
  );

  const numerical: ShorelineNumericalDomain = {
    project: simulationFile.project ? simulationFile.project.toString() : shorelineNumericalDomainInitialState.project,
    cellS: simulationFile.parameters.cellS,

    coastlineFile: I_Coast,
    d: simulationFile.parameters.d,
    ddeep: simulationFile.parameters.ddeep,
    dnearshore: simulationFile.parameters.dnearshore,
    orientationType:
      simulationFile.parameters.orientationType === 1
        ? 'constant'
        : simulationFile.parameters.orientationType === 0
        ? 'varying'
        : 'auto',
    phif: simulationFile.parameters.phif ? simulationFile.parameters.phif : undefined,
    orientationFile: phifFile,
    ld: simulationFile.parameters.ld,
    boundaryTypeStart: simulationFile.parameters.bndrStart.toString(),
    boundaryTypeEnd: simulationFile.parameters.bndrEnd.toString(),
  };

  const simulationTime: ShorelineSimulationTime = {
    reftime: simulationFile.parameters.reftime,
    simend: simulationFile.parameters.simend,
    timestep: simulationFile.parameters.timestep,
    timeType: simulationFile.parameters.timeType === 1 ? 'constant' : 'adaptive',
  };

  const waveClimate: ShorelineWaveClima = {
    wavegenType:
      simulationFile.parameters.wavegenType === 2
        ? 'wave_climate'
        : simulationFile.parameters.wavegenType === 1
        ? 'timeseries'
        : 'constant',
    hm: simulationFile.parameters.hm,
    ts: simulationFile.parameters.ts,
    direction: simulationFile.parameters.direction,
    spread: simulationFile.parameters.spread,
    waveclimaFile: I_WaveClima,
    timeseriesFile: I_Timeseries,
    numTime: simulationFile.parameters.numTime ?? shorelineWaveClimaInitialState.numTime,
    timeseries: timeseries ?? shorelineWaveClimaInitialState.timeseries,
    numWave: simulationFile.parameters.numWave ?? shorelineWaveClimaInitialState.numWave,
    waveclima: waveclima ?? shorelineWaveClimaInitialState.waveclima,
  };

  const modelFeatures: ShorelineModelFeatures = {
    wave_diffraction: simulationFile.parameters.wv_diff == 1,
    clima_change: simulationFile.parameters.cl_chng == 1,
    slrate: simulationFile.parameters.slrate,
    sediment: simulationFile.parameters.sediment.toString(),
    qscal: simulationFile.parameters.qscal,
    calfc: simulationFile.parameters.calfc,
    tper: simulationFile.parameters.tper,
    d50: simulationFile.parameters.d50,
    porosity: simulationFile.parameters.porosity,
    tanbeta: simulationFile.parameters.tanbeta,
    rhos: simulationFile.parameters.rhos,
    rhow: simulationFile.parameters.rhow,
    gamma: simulationFile.parameters.gamma,
    smoothC: simulationFile.parameters.smoothC == 1,
    smoothR: simulationFile.parameters.smoothR == 1,
    smoothrefrac: simulationFile.parameters.smoothrefrac,
    smoothfac: simulationFile.parameters.smoothfac,
  };

  const structure: ShorelineStructure = {
    struct: simulationFile.parameters.struct == 1,
    structFile: I_Struct,
    revetment: simulationFile.parameters.revetment == 1,
    revetFile: I_Revet,
  };

  const nourishment: ShorelineNourishment = {
    nourish: simulationFile.parameters.nourish == 1,
    shoreface: simulationFile.parameters.shoreface == 1,
    numNourishments: simulationFile.parameters.numNourishments ?? shorelineNourishmentInitialState.numNourishments,
    nourishments: nourishments,
    numShorefaceNour: simulationFile.parameters.numShorefaceNour ?? shorelineNourishmentInitialState.numShorefaceNour,
    shorefaceNour: shorefaceNour,
  };

  const plotPropetries: ShorelinePlotProperties = {
    swX: simulationFile.parameters.swX,
    swY: simulationFile.parameters.swY,
    neX: simulationFile.parameters.neX,
    neY: simulationFile.parameters.neY,
    fignryear: simulationFile.parameters.fignryear,
    usefill: simulationFile.parameters.usefill == 1,
    plotwavequiver: simulationFile.parameters.plotwavequiver == 1,
    plotX: simulationFile.parameters.plotX ? simulationFile.parameters.plotX : undefined,
    plotY: simulationFile.parameters.plotY ? simulationFile.parameters.plotY : undefined,
    scale: simulationFile.parameters.scale ? simulationFile.parameters.scale : undefined,
    pltObjNum: simulationFile.parameters.pltObjNum ?? shorelinePlotPropertiesInitialState.pltObjNum,
    refCoast: plotFiles ?? shorelinePlotPropertiesInitialState.refCoast,
    llocation: simulationFile.parameters.llocation ?? shorelinePlotPropertiesInitialState.llocation,
    pdf: simulationFile.parameters.include_pdf == 1,
  };

  const pdfLabel: ShorelinePlotLabelPdf = {
    frame: simulationFile.parameters?.kwargs?.frame ?? shorelinePlotLabelPdfInitialState.frame,
    plotBasemap: simulationFile.parameters?.kwargs?.plot_basemap == 1,
    inpEPSG: simulationFile.parameters?.kwargs?.input_epsg ?? shorelinePlotLabelPdfInitialState.inpEPSG,
    mapProvider: simulationFile.parameters?.kwargs?.basemap_provider ?? shorelinePlotLabelPdfInitialState.mapProvider,
    pageOrientation:
      simulationFile.parameters?.kwargs?.orientation?.toString() ?? shorelinePlotLabelPdfInitialState.pageOrientation,
    label: simulationFile.parameters?.kwargs?.label == 1,
    userIdType: simulationFile.parameters?.kwargs?.userID == 2 ? 'logo' : 'username',
    username: simulationFile.parameters?.kwargs?.userName ?? shorelinePlotLabelPdfInitialState.username,
    logoFile: logoFile
      ? { currentFile: { project: +logoFile.project, name: logoFile.fileid }, fileName: logoFile.fileid }
      : undefined,
    client: simulationFile.parameters?.kwargs?.clientName?.toString() ?? shorelinePlotLabelPdfInitialState.client,
    projectTitle: simulationFile.parameters?.kwargs?.projectTitle ?? shorelinePlotLabelPdfInitialState.projectTitle,
    figureDescription:
      simulationFile.parameters?.kwargs?.figureDescription ?? shorelinePlotLabelPdfInitialState.figureDescription,
    figureNumber: simulationFile.parameters?.kwargs?.figureNumber ?? shorelinePlotLabelPdfInitialState.figureNumber,
    figureTitle: simulationFile.parameters?.kwargs?.figureTitle ?? shorelinePlotLabelPdfInitialState.figureTitle,
    date: simulationFile.parameters?.kwargs?.date ?? shorelinePlotLabelPdfInitialState.date,
  };

  const output: ShorelineOutput = {
    outputFileName: simulationFile.parameters.outputName.toString() ?? '',
  };

  const submission: SrfSubmission = {
    vCpus: simulationFile.cores ? simulationFile.cores : srfSubmissionInitialState.vCpus,
    simulationFile: srfSubmissionInitialState.simulationFile,
  };

  return {
    numerical,
    simulationTime,
    waveClimate,
    modelFeatures,
    structure,
    nourishment,
    plotPropetries,
    pdfLabel,
    output,
    submission,
  };
};
